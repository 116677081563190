import React from 'react'
import { Navbar, Nav, NavDropdown, Form, Button, FormControl } from 'react-bootstrap';
import Logo from '../../assets/applogo.png';
import LizLogo from '../../assets/lizlogo.png'

type HeaderProps = {
    title: string
}

function Header(props: HeaderProps) {
    return (
        <><Navbar className='font' collapseOnSelect color="white" bg="dark" expand="md" variant="dark" fixed="top" style={{ boxShadow: '1px 1px 1px 1px rgba(0,0,0,0.3)' }}>
            <Navbar.Brand href="/" className="ps-3 d-flex">
                <img style={{ width: '35px' }} src={props.title === '티지' ? Logo : LizLogo} />
                <div className='align-self-center ms-3'>
                    {props.title}
                </div>
            </Navbar.Brand>
            <Navbar.Toggle color="white" aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto text-white">
                    <Nav.Link href="/">홈</Nav.Link>
                    <NavDropdown title="약관" id="basic-nav-dropdown">
                        <NavDropdown.Item href={`/personal`}>개인정보 처리방침</NavDropdown.Item>
                        <NavDropdown.Item href={`/service`}>서비스 이용약관</NavDropdown.Item>
                        {/* <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item> */}
                    </NavDropdown>
                    <NavDropdown title="다른 앱 둘러보기" id="basic-nav-dropdown">
                        <NavDropdown.Item href="#action/3.1">준비중입니다.</NavDropdown.Item>
                        {/* <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item> */}
                    </NavDropdown>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
        </>
    )
}

export default Header