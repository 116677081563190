import React from 'react'

function PersonalTerm() {
    return (
        <div className='text-white bg-dark mt-5 mb-5 pt-5 pb-5 w-100 px-5' style={{}}>
            보호대책
            <br />● 개인정보자 동 수집장치의 설치/운영 및 거부에 관한 사항
            <br />● 스마트폰 앱 관리
            <br />● 이용자의 의무
            <br />● 개인정보 보호 책임자 및 민원 처리 담당 부서
            <br />● 개인정보 보호 침해 신고 및 상담
            <br />● 기타 사항
            <br />● 고지의 의무
            <br />
            <br />제 1 조 (개인정보 처리 목적)
            <br />
            <br />1. 단계별 처리하는 개인정보 항목
            <br />1) 본인인증시(필수)
            <br />이름, 생년월일, 성별, 내/외국인여부, 휴대전화번호(본인 확인 기관으로부터 수집/ 온라인수집)
            <br />※ 휴대폰번호가 이후 앱 내 로그인을 위한 아이디로 활용됩니다.
            <br />2) 회원가입시(필수 및 선택)
            <br />필수 항목 : 닉네임, 지역, 나이
            <br />선택 항목 : 프로필이미지
            <br />3) 로그인시(필수)
            <br />휴대전화번호
            <br />4) 프로필수정시(선택)
            <br />필수 수집 항목 : 닉네임, 지역, 나이
            <br />선택 수집 항목 : 프로필 이미지, 이용자가 설정한 키/몸무게, 체형, 이용목적, 성향, 헤어스타일, 흡연, 음주여부(온라인수집)
            <br />4) 구매시: 대행사에서 수집
            <br />5) 금전적 보상 요청시(환전시) (필수)
            <br />이용자성명, 휴대전화번호, 이메일, 주민등록번호(온라인수집)
            <br />※ 국세 기본법에 의한 소득 신고 및 원천징수 영수증 발급 목적
            <br />환전받는분정보: 예금주, 은행명, 계좌번호(온라인수집)
            <br />※ 금전적 보상 절차에 따라 본인확인 서류정보가 추가로 수집 및 처리될 수 있습니다.
            <br />6) 결제관련문의(구매일반/취소/오류) 시(필수)
            <br />회원번호, 닉네임, 이메일, 휴대전화번호, 결제내역(결제일시, 결제금액, 결제상품)
            <br />가상계좌 환불 시 본인 통장사본(예금주명, 계좌번호, 은행명), 본인확인 서류 정보가 추가로 수집 및 처리 될 수
            <br />있습니다.
            <br />7) 고객문의시(필수)
            <br />이메일, 문의내용(온라인수집)
            <br />※ 서비스 이용개선 및 분쟁해결을 위해 본인확인 서류정보가 추가로 수집 및 처리될 수 있습니다.
            <br />8) 이벤트참여시(필수)
            <br />이름, 아이디, 닉네임, 연락처, 이메일(온라인수집)
            <br />9) 마케팅활용동의시(선택)
            <br />성별, 생년월일, 닉네임, 연락처, 이메일, SNS 프로필정보(이메일, 닉네임, 이름) (온라인수집)
            <br />※ 본인인증, 성인인증, 법정대리인인증, 서비스 이용 및 고객 문의(금전적 보상 요청, 구매 취소, 문의 등) 시 안내
            <br />받은 본인확인 서류를 통해 단계별 필요한 정보가 최소 수집 및 처리될 수 있습니다.
            <br />10) 연락처차단시(선택)
            <br />이용자의 연락처 휴대전화번호 리스트
            <br />

            <br />2. 개인정보 처리목적
            <br />1) 서비스가입, 서비스이용및상담, 프로필수정, 부정이용 확인 및 방지를 위하여 개인정보를 처리합니다.
            <br />2) 본인 및 연령확인을 위하여 개인정보를 처리합니다.
            <br />3) LIVE 서비스 게시 및 참여, 사용자를 위한 추천 콘텐츠 제공을 위하여 개인정보를 처리합니다.
            <br />4) 다양한 콘텐츠를 빠르고 효율적인 검색하기 위한 기능을 필수로 제공하고 있으며, 이를 위해 이용자의 개인정보를
            <br />처리합니다. 이 경우 개인정보는 가명 처리되어, 개인을 알아볼 수 없도록 활용됩니다.
            <br />5) 구매 및 구매취소 관련 업무, 금전적 보상 관련 업무(계좌인증, 실명인증, 원천징수 영수증 발급, 소득신고), 환불
            <br />요청 대응, 이용자 상담 및 대응, 고지사항 전달, 계정 제재 및 이용 제한 조치를 위하여 개인정보를 처리합니다.
            <br />6) 건전한 콘텐츠생성, 서비스품질개선, 불법촬영물 등의 유통방지를 위하여 개인정보를 처리합니다.
            <br />7) 이벤트 참여, 광고성 정보 제공 등 마케팅 목적으로 개인정보를 처리 할 수 있으며, 이 경우 사전에 이용자의 별도
            <br />동의를 받습니다.
            <br />8) 서비스 이용기록 분석, 서비스 이용에 대한 통계 및 분석에 따른 맞춤 서비스 제공 등에 개인정보를 처리 할 수
            <br />있습니다.
            <br />
            <br />3. 개인정보 처리 및 보유기간
            <br />회사는 개인정보 수집시 동의받은 기간 동안 개인정보를 처리 및 보유합니다.
            <br />1) 회사가 수집 ∙ 이용한 개인정보는 이용자의 탈퇴 시 즉시 파기됩니다. 단, 부정 이용 및 재가입 방지를 위해 필요한
            <br />정보를 1일간 보관 후 파기됩니다.
            <br />2) LIVE 제작자 및 LIVE 참여자의 실시간 오디오 콘텐츠, 채팅은 건전한 콘텐츠 생성, 서비스 품질 개선, 불법촬영물
            <br />등의 유통 방지, 청소년 보호 등을 목적으로 신고가 들어올 시 90일간 보관 후 즉시 파기되며 이외 정보들은 즉시
            <br />파기됩니다.
            <br />3) 회사는서비스이용기록 분석, 서비스이용에 대한 통계및 분석을 위해 개인정보를 처리할경우복원이 불가능 하도록
            <br />비식별조치할수있습니다.
            <br />4) 탈퇴한 회원의 일부 개인정보는 관련 법령에 따라 보관이 필요한 경우 별도의 DB로 이전되어 분리보관됩니다.
            <br />대금 결제 및 재화 등의 공급에 관한 기록 (5년) /계약 또는 청약 철회 등에 관한 기록(5년) / 소비자 불만 또는 분쟁
            <br />처리에 관한 기록(3년) *전자상거래 등에서의 소비자 보호에 관한 법률
            <br />웹사이트 방문 기록(3개월) *통신 비밀 보호법
            <br />원천징수 신고에 관한기록(5년) *소득세법, 국세기본법
            <br />※ 별도 분리보관된 이용자의 개인정보는 최소한의 취급자만 접근 가능하도록 통제하고 있습니다.
            <br />
            <br />제 2 조 (개인정보의 제3자 제공)
            <br />1. 회사는 원칙적으로 수집 및 이용 목적 범위를 초과하거나 이용자의 동의없이 개인정보를 제 3자에게 제공하지
            <br />않습니다.
            <br />2. 이용자의 개인정보를 제3자에게 제공해야 될 경우 관련법령에서 요구하는 개인정보 제3자 제공에 관한 사항을
            <br />이용자에게 명확히 안내하고 동의를 받겠습니다.
            <br />3. 회사는 법령에 근거가 있는 경우 이용자의 동의없이 개인정보를 제공할 수 있습니다.
            <br />1) 법률에 특별한 규정이있거나 법령상 의무를 준수하기 위한경우
            <br />2) 수사목적으로 압수수색 검증 영장 등에 따른 수사 기관의 요구가 있는경우
            <br />3) 수사기관등에서 자살, 실종 등과 같은 범죄가 의심되는 긴급 사안의 업무 협조를 요청할 경우
            <br />
            <br />제 3 조 (개인정보 처리 위탁)
            <br />1. 회사는 이용자에게 보다 나은 서비스를 제공하기 위해 반드시 필요한 경우 개인정보 처리 업무 일부를 외부에 위탁할
            <br />수도있습니다.
            <br />2. 회사는 개인정보 처리 업무 위탁 시 계약 등 문서를 통해 수탁자에게 개인정보보호 관련 법령을 준수하도록 책임을
            <br />요구하고있습니다.
            <br />3. 회사는 개인정보를 처리 위탁하는 경우 계약된 수탁자가 개인정보보호 관련 법령에서 요구하는 안전성 확보 조치
            <br />기준을 충족하여 업무를 진행하도록 계약서에 관련 내용을 반영하고 있습니다.
            <br />4. 회사는 수탁자에게 개인정보의 안전한 처리, 개인정보 접근 또는 접속 기록 관리, 목적 외 이용 금지, 위탁업무의
            <br />재위탁 제한, 암호화 등 이용자의 개인정보를 안전하게 처리하기 위한 기술적 관리적 보호조치를 수탁자에게 요구하고
            <br />있으며, 수탁자가 개인정보를 안전하게 관리하고 있는 지정기적으로 관리 감독을 진행하고있습니다. 단, 수탁자의 보안
            <br />수준 및 규모 등을 고려하여 관리감독을 진행하고 있습니다.
            <br />5. 서비스 이용을 위해 국내업체에 개인정보의 처리를 위탁하는 현황은 아래와 같습니다.
            <br />● NICE평가정보㈜: 본인인증, 성인인증
            <br />● 회원탈퇴시 혹은 서비스종료시까지(이용자가 해당서비스를 이용하는 경우에만 처리위탁됨)
            <br />6. 서비스 이용을 위해 해외업체에서 개인정보를 처리위탁 및 보관하는 현황은 아래와같습니다.
            <br />● 구글 /firebase,미국, 이메일/앱푸시 발송, 서비스 이용 시점에 네트워크를 통한 전송, 성명, 생년월일, 성별,
            <br />이메일및서비스, 이용관련 정보 회원탈퇴 또는 위탁계약 종료시까지
            <br />
            <br />제 4 조 (이용자의 권리와 그 행사 방법)
            <br />1. 이용자는 언제든지 본인의 개인정보를 조회하거나 수정할 수 있으며 회원 탈퇴 절차를 통해 이용자 본인의 개인정보를
            <br />철회 할 수 있습니다.
            <br />2. 개인정보의 수정을 원하거나 회원탈퇴를 원하는 경우 다음과 같은 절차에 의해 진행할 수 있습니다.
            <br />1) 이용자는 회사의 App의 설정을 통해 개인정보를 수정하실 수 있습니다.
            <br />2) 이용자는 tgDev95@gmail.com을 통해서도 수정, 삭제 등을 요청하실 수 있습니다. 다만, 해당 요청을 하는
            <br />정보주체가 본인임을 명확하게 확인하기 위한 업무상 절차가 요구될 수 있습니다. 이 경우 해당 업무 처리를 위해
            <br />규제기관에서 별도로 규정하고 있는 표준 서식을 통해 접수요청을 할 수도 있으며, 이에 따라 진행이 가능할 수
            <br />있습니다.
            <br />3) 이용자는 서비스를 탈퇴하고자 하는 경우 [설정] 메뉴의[탈퇴하기]를 클릭하여 탈퇴하실 수 있습니다.
            <br />3. 이용자가 개인정보 수정, 삭제, 탈퇴 등 권리를 요청했다 하더라도 이용자의 귀책사유에 따른 회사의 서비스 정지 정책이
            <br />적용 중인 경우 본 권리요청이 즉시 적용되지 않을 수 있습니다.
            <br />4. 이용자가 동의 철회, 개인정보의 삭제 또는 처리 정지를 요청했다하더라도, 다른 법령에 따라 수집하거나, 보존되어야만
            <br />하는 경우 법령준수에 의한 보존 기한이 우선 적용될 수 있습니다.
            <br />5. 이용자가 서비스 이용기록 등 열람∙ 조회 요청 시 제3자의 정보가 포함된 경우 해당 정보는 열람∙조회 처리에 제한이
            <br />있을 수 있습니다.
            <br />6. 이용자는 개인정보의 열람·정정·삭제·처리정지 요구 및 요구거절에 대한 이의제기를 하기 위한 창구는 아래와 같습니다.
            <br />메일: tgdev95@gmail.com
            <br />7. 이용자는 만 19세 이상인 경우에만 회원 가입이 가능하며, 원칙적으로 만 19세 미만 아동의 개인정보는 수집하지
            <br />않습니다.
            <br />
            <br />제 5 조 (개인정보 파기절차 및 방법)
            <br />1.회사는 이용자의 개인정보가 업무 목적 달성 또는 보유 기간을 달성하여 파기가 필요한 경우, 해당 개인정보를 즉시
            <br />파기하고 있습니다. 다만 관련 법령에 의해 보관해야하는 정보는 개별 법령이 정한 일정기간을 준수하고 있습니다.
            <br />2,개인정보의 파기 절차 및 방법은 아래와 같습니다.
            <br />1) 이용자의 개인정보 수집 및 이용 목적이 달성되면 지체 없이 파기됩니다. 종이에 출력된 개인정보는 분쇄기로
            <br />분쇄하거나 소각을 통하여 파기되며, 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을
            <br />사용하여 삭제합니다.
            <br />2) 다른 법령에 의해서 보존되어야 하는 개인정보의 경우에는 별도의 DB에 관련 법령 및 내부 규정을 준수를 위해
            <br />일정기간동안 안전하게 보관된 후 파기됩니다.
            <br />
            <br />제 6 조 (개인정보의 기술적/관리적 보호대책)
            <br />회사는 이용자 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지않도록 안전성 확보를 위하여
            <br />다음과 같은 기술적/관리적 보호 대책을 마련하고있습니다.
            <br />1.개인정보의 암호화
            <br />1) 회사는 법령에서 요구하는 암호화를 수행하고 있습니다. 계좌번호 등 금융정보는 법령에서 요구하는 알고리즘을
            <br />충족하여 저장 및 관리되고 있습니다.
            <br />2) 회사는 이용자의 개인정보가 전송되는 구간의 암호화를 하고 있으며, 사내에서 업무 수행 시 중요 데이터는
            <br />암호화하거나, 파일에 암호를 설정하여 사용하고 있습니다.
            <br />2.해킹 등에 대비한 대책
            <br />1) 회사는 해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고
            <br />있습니다.
            <br />2) 회사는 더욱 향상된 보안조치를 확보할 수 있도록 가능한 기술적 보호조치를 확보하기 위하여 지속적으로 노력하고
            <br />있습니다.
            <br />3) 회사는 외부로부터의 무단 접근을 통제하고 있으며, 시스템 보안성을 확보하기 위한 가능한 모든 기술적 장치를
            <br />갖추려 노력하고 있습니다.
            <br />3.개인정보 취급자 최소화 및 지속적 교육
            <br />1) 회사는 개인정보를 취급하는 직원을 최소화하고 있으며, 개인정보 취급자에 대한 교육을 정기 및 수시로 실시하여 본
            <br />방침의 이행 및 개인정보 보호관련 법령 준수 여부를 확인하고 지속적으로 강조하고 있습니다.
            <br />2) 개인정보 취급자는 해당 권한이 적절한지에 대한 검토가 이루어지고 있으며, 최소한의 권한이 부여될 수 있는 조치를
            <br />진행하고있습니다.
            <br />3) 회사는 개인정보 취급자의 계정 비밀번호를 정기적으로 갱신하고 있습니다.
            <br />
            <br />제 7 조 (스마트폰 앱 관리)
            <br />1.스마트폰 앱을 통하여 서비스를 이용 시 단말기 정보 등에 접근할 필요가 있는 경우에는 이를 고지하고 승인을
            <br />얻습니다.
            <br />2.스마트폰 앱 접근 권한에 대해 권한을 변경하실 경우에는 스마트폰 상에서 '설정{`>`}애플리케이션관리자'에서 변경이
            <br />가능합니다.
            <br />
            <br />제 8 조 (이용자의 의무)
            <br />1.이용자는 본인의 개인정보를 안전하게 지키기 위해 개인정보보호에 관한 법률을 준수할 의무가 있습니다.
            <br />2.이용자는 자신의 개인정보를 안전하게 보호할 의무가 있으며, 회사는 이용자 개인의 부주의로 인해 발생된 일에
            <br />대해서는 책임을 지지 않습니다.
            <br />3.이용자는 본인의 개인정보를 최신의 상태로 정확하게 입력하고 유지할 의무가 있습니다. 이용자의 부정확한 정보
            <br />입력으로 인하여 발생하는 문제의 책임은 이용자 본인에게 있습니다.
            <br />4. 이용자는 타인의 개인정보를 도용하여 회원가입 또는 서비스 이용 시 회원자격 상실과 함께 개인정보에 관한 법률에
            <br />의거하여 처벌될 수 있습니다.
            <br />5. 이용자는 타인의 개인정보를 침해하거나 유출하지 않을 의무도 가지고 있습니다. 서비스 이용 중 다른 이용자에 대해
            <br />알게된 개인정보를 언급하거나, 유출할 경우 개인정보 관련법령에 의거하여 처벌될 수 있습니다.
            <br />제 9 조 (개인정보보호 책임자 및 민원 처리 담당 부서)
            <br />● 개인정보보호책임자
            <br />- 성명: 김승훈
            <br />- 직급: 대표
            <br />- 직책 : CEO(대표)
            <br />- 연락처: tgDev95@gmail.com
            <br />● 개인정보민원처리담당부서
            <br />- 부서명: 서비스운영
            <br />- 연락처: tgDev95@gmail.com
            <br />● 회사가 제공하는 서비스를 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해 구제 등에 관한
            <br />사항을 개인정보 보호 책임자 및 민원처리 부서로 문의하실 수 있으며, 회사는 이용자들의 신고 및 문의사항에 대해
            <br />신속하게 충분한 답변을 드릴 것 입니다.
            <br />제 10 조 (개인정보보호 침해 신고 및 상담)
            <br />기타 개인정보 침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
            <br />- 개인정보분쟁조정위원회(www.kopico.go.kr/ 전화1833-6972)
            <br />- 개인정보침해신고센터(privacy.kisa.or.kr/ 국번없이118)
            <br />- 대검찰청 사이버범죄수사단 (www.spo.go.kr/ 02-3480-3571)
            <br />- 경찰청사이버수사국(https://ecrm.cyber.go.kr/minwon/main)/ 국번없이182
            <br />
            <br />제 11 조 (기타 사항)
            <br />1.회사는 이용자에게 다른 회사의 웹사이트 또는 자료에 대한 링크를 제공할 수 있습니다. 이 경우 회사는 외부 사이트
            <br />및 자료에 대해 통제할 수 있는 권한이 없기 때문에 외부 사이트를 통해 제공 받는 서비스나 자료의 유용성에 대해
            <br />책임질 수 없으며 보증할 수 없습니다. 따라서 회사의 페이지가아닌 타 사이트의 페이지로 옮겨 가실 경우 반드시 해당
            <br />사이트의 신뢰성을 확인하기 바라며, 방문하신 사이트의 개인정보처리방침은 회사와 무관하므로 새로 방문한 사이트의
            <br />개인정보 처리방침 등 정책을 확인해주시기 바랍니다.
            <br />
            <br />제 12 조 (고지의 의무)
            <br />이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경 내용의 추가, 삭제 및 정정이 있는 경우에는
            <br />변경사항의 시행 7일 전부터 공지사항 등을 통하여 알려드릴 것입니다. 또한 관련 법령이나 회사 정책의 변경으로
            <br />불가피하게 개인정보처리방침을 변경해야 하는 경우, 공지사항을 통해 빠르게 알려드리고 있으니 참고하여 주시기
            <br />바랍니다.
            <br />
            <br />개인정보처리방침버전번호: Ver1.0
            <br />공지일자: 2023년06월04일
            <br />시행일자: 2023년06월04일
        </div>
    )
}

export default PersonalTerm