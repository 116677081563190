import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from './Header'

type LayoutProps = {
    title: string
}

const Layout = (props: LayoutProps) => {
    return (
        <>
            <Header title={props.title} />
            <main>
                <Outlet />
            </main>
        </>
    )
}

export default Layout