import React from 'react'
import GoogleButton from '../../assets/google-play-badge.png'
import IosButton from '../../assets/ios-white.svg'
import Main1 from '../../assets/liz-main1.png'
import Main2 from '../../assets/liz-main2.png'
import Main3 from '../../assets/liz-main3.png'
import Main4 from '../../assets/liz-main4.png'
import Main5 from '../../assets/liz-main5.png'
import Main6 from '../../assets/liz-main6.png'
import Main7 from '../../assets/liz-main7.png'
import QR from '../../assets/liz_qr.png'
import { AnimationOnScroll } from 'react-animation-on-scroll';
function TgMain() {
    return (
        <>
            <div className='bg-dark pb-5'>
                <div className='bg-dark pb-5'>

                    <div className='container-fluid min-vh-100 w-100 tg-background d-flex main-container pt-5'>
                        <div>
                            <h1 className='text-center text-white pt-5 display-4'>
                                {`레즈비언의`}
                            </h1>
                            <h1 className='text-center text-white display-3 '>
                                {`새로운 소통공간, 리즈`}
                            </h1>
                        </div>
                        <div className='justify-content-end mt-auto'>
                            <div className='w-100 row'>
                                <div className='col-md-6 col-md-push-8 d-flex justify-content-center'>
                                    <div className='row w-100 justify-content-center'>
                                        <img className='col-md-7 col-lg-7 col-sm-8 col-8 align-self-center' style={{ objectFit: 'contain' }} src={Main1} />
                                    </div>
                                </div>
                                <div className='col-md-6 col-sm-8 col-md-pull-6 mt-5 mx-auto mb-3'>
                                    <div className='d-flex justify-content-center m-auto h-100'>
                                        <div className='d-grid align-items-center'>
                                            <div className='d-flex justify-content-center'>
                                                <div className='col-md-10 col-lg-10 d-flex col-sm-8 col-8'>
                                                    <div>
                                                        <div>
                                                            <a className='w-100' href='https://play.google.com/store/apps/details?id=com.sh.biteapplication'>
                                                                <img className='w-100' src={GoogleButton} />
                                                            </a>
                                                        </div>
                                                        <div>
                                                            <a className='w-100' href='https://apps.apple.com/us/app/%EB%A6%AC%EC%A6%88-%EB%A0%88%EC%A6%88%EB%B9%84%EC%96%B8-%EB%A0%88%EC%A6%88-%EB%B0%A9%EC%86%A1-%EB%8D%B0%EC%9D%B4%ED%8C%85-%EC%BB%A4%EB%AE%A4%EB%8B%88%ED%8B%B0-%EC%B1%84%ED%8C%85/id6450359052'>
                                                                <img className='w-100' src={IosButton} />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='landingBox w-100 bg-dark'>
                    <div className='d-grid align-items-center'>
                        <div className='row w-100'>
                            <AnimationOnScroll className='col' animateIn="animate__fadeInLeft">
                                <div className='col-md-10 col-md-push-8 d-flex mx-auto'>
                                    <img className='col-md-6 col-lg-6 col-xl-8 col-sm-8 col-8 align-self-center' src={Main2} style={{ objectFit: 'contain' }} />
                                </div>
                            </AnimationOnScroll>
                            <AnimationOnScroll className='col d-flex' animateIn="animate__fadeInRight">
                                <div className='align-items-center d-grid '>
                                    <div>
                                        <h5 className='text-white text-center'>
                                            더욱 안전해진<br /> PASS 본인인증 기능<br /><br /><br />
                                        </h5>
                                        <h6 className='text-white text-center'>
                                            패스 인증을 통해 간편하게
                                            <br /> 오직 성인 여성만 가입할 수 있어요 <br /><br />

                                        </h6>
                                    </div>
                                </div>
                            </AnimationOnScroll>
                        </div>
                    </div>
                </div>
                <div className='landingBox w-100 bg-dark'>
                    <div className='d-grid align-items-center '>
                        <div className='row w-100'>
                            <AnimationOnScroll className='col' animateIn="animate__fadeInLeft">
                                <div className='col-md-10 col-md-push-8 d-flex mx-auto'>
                                    <img className='col-md-6 col-lg-6 col-xl-8 col-sm-8 col-8 align-self-center' src={Main3} style={{ objectFit: 'contain' }} />
                                </div>
                            </AnimationOnScroll>
                            <AnimationOnScroll className='col d-flex' animateIn="animate__fadeInRight">
                                <div className='align-items-center d-grid'>
                                    <div>
                                        <h5 className='text-white text-center'>
                                            "유저리스트 탐색"<br /><br />
                                        </h5>
                                        <h6 className='text-white text-center'>
                                            다양한 유저리스트를 확인하고 <br />
                                            팔로우하거나 채팅을 보내보세요 <br />
                                            <br /> <br />
                                        </h6>
                                    </div>
                                </div>
                            </AnimationOnScroll>
                        </div>
                    </div>
                </div>
                <div className='landingBox w-100 bg-dark'>
                    <div className='d-grid align-items-center '>
                        <div className='row w-100'>
                            <AnimationOnScroll className='col' animateIn="animate__fadeInLeft">
                                <div className='col-md-10 col-md-push-8 d-flex mx-auto'>
                                    <img className='col-md-6 col-lg-6 col-xl-8 col-sm-8 col-8 align-self-center' src={Main4} style={{ objectFit: 'contain' }} />
                                </div>
                            </AnimationOnScroll>
                            <AnimationOnScroll className='col d-flex' animateIn="animate__fadeInRight">
                                <div className='align-items-center d-grid'>
                                    <div>
                                        <h5 className='text-white text-center'>
                                            "간편한 대화"<br /><br />
                                        </h5>
                                        <h6 className='text-white text-center'>
                                            1:1 채팅을 통해 <br />
                                            마음에 드는 상대와 바로 채팅을 <br />
                                            시작할 수 있어요.
                                        </h6>
                                    </div>
                                </div>
                            </AnimationOnScroll>
                        </div>
                    </div>
                </div>
                <div className='landingBox w-100 bg-dark'>
                    <div className='d-grid align-items-center '>
                        <div className='row w-100'>
                            <AnimationOnScroll className='col' animateIn="animate__fadeInLeft">
                                <div className='col-md-10 col-md-push-8 d-flex mx-auto'>
                                    <img className='col-md-6 col-lg-6 col-xl-8 col-sm-8 col-8 align-self-center' src={Main5} style={{ objectFit: 'contain' }} />
                                </div>
                            </AnimationOnScroll>
                            <AnimationOnScroll className='col d-flex' animateIn="animate__fadeInRight">
                                <div className='align-items-center d-grid'>
                                    <div>
                                        <h5 className='text-white text-center'>
                                            "맞춤 필터링"<br /><br />
                                        </h5>
                                        <h6 className='text-white text-center'>
                                            지역, 나이, 성향, 목적, 체형 등 <br />
                                            원하는 설정으로 탐색해보세요. <br />
                                        </h6>
                                    </div>
                                </div>
                            </AnimationOnScroll>
                        </div>
                    </div>
                </div>
                <div className='landingBox w-100 bg-dark'>
                    <div className='d-grid align-items-center '>
                        <div className='row w-100'>
                            <AnimationOnScroll className='col' animateIn="animate__fadeInLeft">
                                <div className='col-md-10 col-md-push-8 d-flex mx-auto'>
                                    <img className='col-md-6 col-lg-6 col-xl-8 col-sm-8 col-8 align-self-center' src={Main6} style={{ objectFit: 'contain' }} />
                                </div>
                            </AnimationOnScroll>
                            <AnimationOnScroll className='col d-flex' animateIn="animate__fadeInRight">
                                <div className='align-items-center d-grid'>
                                    <div>
                                        <h5 className='text-white text-center'>
                                            "라이브 음성 방송"<br /><br />
                                        </h5>
                                        <h6 className='text-white text-center'>
                                            얼굴 노출 없이 오로지 음성으로 <br />
                                            재미있는 대화를 나눠보세요 <br />
                                        </h6>
                                    </div>
                                </div>
                            </AnimationOnScroll>
                        </div>
                    </div>
                </div>
                <div className='landingBox w-100 bg-dark'>
                    <div className='d-grid align-items-center '>
                        <div className='row w-100'>
                            <AnimationOnScroll className='col' animateIn="animate__fadeInLeft">
                                <div className='col-md-10 col-md-push-8 d-flex mx-auto'>
                                    <img className='col-md-6 col-lg-6 col-xl-8 col-sm-8 col-8 align-self-center' src={Main7} style={{ objectFit: 'contain' }} />
                                </div>
                            </AnimationOnScroll>
                            <AnimationOnScroll className='col d-flex' animateIn="animate__fadeInRight">
                                <div className='align-items-center d-grid'>
                                    <div>
                                        <h5 className='text-white text-center'>
                                            "자유로운 게시판"<br /><br />
                                        </h5>
                                        <h6 className='text-white text-center'>
                                            일상, 데이팅, 모임, 각종 고민 등 <br />
                                            자유 및 익명게시판을 이용해보세요 <br />
                                        </h6>
                                    </div>
                                </div>
                            </AnimationOnScroll>
                        </div>
                    </div>
                </div>
                <div >
                    <h1 className='text-white text-center mt-5'>
                        지금 바로 시작해보세요
                    </h1>
                    <div className='d-flex justify-content-center col-6 mx-auto'>
                        <img className='col-6 mt-3 mx-auto' src={QR} />
                    </div>
                    <div className='d-flex justify-content-center text-white mt-3 mb-5 fs-5'>
                        QR코드로 앱스토어로 이동
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <div className=' d-flex justify-content-center'>
                                <a className='col-10 col-sm-12 col-md-6 col-lg-6 d-flex justify-content-center' href='https://play.google.com/store/apps/details?id=com.sh.biteapplication'>
                                    <img className='w-75' src={GoogleButton} />
                                </a>
                            </div>
                        </div>
                        <div className='col-6 d-grid'>
                            <div className=' d-flex justify-content-center'>
                                <a className='col-10 col-sm-12 col-md-6 col-lg-6 d-flex justify-content-center' href='https://apps.apple.com/us/app/%EB%A6%AC%EC%A6%88-%EB%A0%88%EC%A6%88%EB%B9%84%EC%96%B8-%EB%A0%88%EC%A6%88-%EB%B0%A9%EC%86%A1-%EB%8D%B0%EC%9D%B4%ED%8C%85-%EC%BB%A4%EB%AE%A4%EB%8B%88%ED%8B%B0-%EC%B1%84%ED%8C%85/id6450359052'>
                                    <img className='w-75' src={IosButton} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <h3 className='text-white text-center mt-5'>
                        리즈 관련 문의사항 <br /><br />
                        mail : tgDev95@gmail.com<br />
                        phone : 0507-1387-4665
                    </h3>
                </div>
            </div >
        </>
    )
}

export default TgMain;
