
import {
    createBrowserRouter, createRoutesFromElements, Route, Routes,
} from "react-router-dom";
import Layout from "../components/Layout";
import LizMain from "../screens/liz/LizMain";
import TgMain from "../screens/TgMain";
import PersonalTerm from '../screens/PersonalTerm'
import ServiceTerm from '../screens/ServiceTerm'
import LizPersonalTerm from '../screens/liz/PersonalTerm'
import LizServiceTerm from '../screens/liz/ServiceTerm'
const RootNavigation = () => {
    return (
        <Routes>
            <Route path='*' element={<div>404 NOT FOUND</div>} />
            {/* 
            <Route element={<Layout title={"티지"} />}>
                <Route path={'/'} element={<TgMain />} />
                <Route path={'/personal'} element={<PersonalTerm />} />
                <Route path={'/service'} element={<ServiceTerm />} />
            </Route> */}
            <Route element={<Layout title={"리즈"} />}>
                {/* <Route path={'/'} element={<TgMain />} /> */}
                <Route path={'/'} element={<LizMain />} />
                <Route path={'/personal'} element={<LizPersonalTerm />} />
                <Route path={'/service'} element={<LizServiceTerm />} />
                <Route path={'/app-ads.txt'} element={<LizServiceTerm />} />
                {/* <Route path={'/12'} element={<div>455</div>} /> */}
            </Route>
        </Routes>
    )
}

export default RootNavigation